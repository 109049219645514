<template>
    <div class="abilityDetails">

        <!-- 标题 -->
        <div class="title">心愿达摩院</div>
        <div class="empty"></div>
        <div class="content">

            <!-- 三行文字 -->
            <topText :title="title" :subtitle="subtitle"></topText>

            <!-- 按钮 -->
            <div class="btns">
                <div v-for="(item, index) in dataList" :key="index" @click="handleIndex(index)">
                    <button :class="{ active: isActive === index }">{{ item }}</button>
                </div>
                <input class="inp" type="text" placeholder="输入您的心愿">
                <div class="horizontalline"></div>
            </div>
            <!-- 最下面按钮 -->
            <button class="immediate">立即改善</button>
        </div>
    </div>
</template>

<script>
import topText from './components/topText.vue'
export default {
  name: 'DesireDharma',
  components: {
    topText
  },
  data () {
    return {
      dataList: ['升职加薪', '爱情甜蜜', '家庭和谐', '财源广进', '工作顺利', '身体健康'],
      title: '心愿达摩院',
      subtitle: '商务人士能量管理',
      isActive: 2
    }
  },
  methods: {
    handleIndex(i) {
        this.isActive = i
    }
  },
  mounted () {
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
.abilityDetails {
    // height: 100%;
    background: linear-gradient(rgba(165, 135, 255, 0.65) 100%, rgba(136, 103, 237, 0.6)100%), url("https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/desireDharma.png");
    background-size: 100%;
    background-color: #9966FF;
    color: #4f4f4f;
    font-size: 23px;
    position: relative;

    .title {
        text-align: center;
        color: #fff;
        padding: 20px;
    }

    .empty {
        width: 150px;
        height: 18px;
        background: #FF8563;
        border-radius: 10px;
        margin: 160px 10px 0 120px;
    }

    .content {
        width: 100%;
        border-radius: 30px;
        background: #fff;
        margin-top: -10px;
        color: #808080;

        .btns {
            margin: 12px;
            font-size: 17px;
            display: flex;
            flex-wrap: wrap;

            button,
            input {
                width: 160px;
                height: 40px;
                margin: 10px 0 10px 10px;
                border-radius: 20px;
                border: 1px solid #ccc;
                background: #F7F8F8;
            }
            .active {
                    background: #FFCEC7;
                }

            .inp {
                width: 330px;
                text-align: center
            }

            .horizontalline {
                width: 300px;
                border-bottom: 2px dashed;
                margin: 30px 20px;
            }
        }

        .immediate {
            width: 180px;
            height: 60px;
            margin: 10px 100px;
            font-size: 25px;
            border: 1px solid #ccc;
            border-radius: 30px;
            background: #ffaa8f;
            color: #fff;
        }
    }
}
</style>
